import type { ActionFunctionArgs, MetaFunction } from "@remix-run/node";
import { DevicePhoneMobileIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import { Form, useNavigation } from "@remix-run/react";

import React from "react";

import { getPageTitle } from "~/operations/getPageTitle";
import { Field, Label } from "~/components/base/fieldset";
import { checkIsValidPhoneNumber } from "~/operations/checkIsValidPhoneNumber";
import { Input } from "~/components/base/input";
import { action } from "./action";
import { SubmitButton } from "~/components/submitButton";
export { action };

export const meta: MetaFunction = () => {
  return [{ title: getPageTitle("Login") }];
};

export default function LoginIndex() {
  const [usingSMS, setUsingSMS] = React.useState(false);
  const searchParams = new URLSearchParams({ index: "" });

  if (usingSMS) {
    searchParams.set("usingSMS", "");
  }

  return (
    <Form
      className="space-y-6"
      action={`/auth/login?${searchParams.toString()}`}
      method="post"
      noValidate={usingSMS}
    >
      <Field>
        <Label>Enter your E-mail address or phone number.</Label>
        <Input
          type={usingSMS ? "tel" : "email"}
          name={usingSMS ? "phone" : "email"}
          required
          autoFocus
          placeholder=""
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setUsingSMS(checkIsValidPhoneNumber(newValue));
          }}
        />
      </Field>
      <SubmitButton
        className="w-full"
        busyContent={"Getting Code..."}
        idleIcon={usingSMS ? <DevicePhoneMobileIcon /> : <EnvelopeIcon />}
        idleContent={"Get Code"}
      />
    </Form>
  );
}
